import { Typography } from '@mui/material'
import React from 'react'
import UserTable from './UserTable'

const UsersSection = () => {
  return (
    <div>
       <UserTable/>
    </div>
  )
}

export default UsersSection
